<template>
<div>
    <v-menu v-model="shower" :position-x="x" :position-y="y" top offset-y close-on-content-click close-on-click  transition="slide-y-transition">
      <v-list dense>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item v-for="(item, i) in items" :key="i" @click="goto(item.to)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-menu>
    <inboxdetail
    :show="openinboxdetail"    
    :filedata="currentinboxfile"       
    @closeinboxdetail="openinboxdetail = false"
    @openinboxdetail="openinboxdetail = true"
    ></inboxdetail>
</div>
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const inboxdetail = () => import("@/components/optional/dialog-detail");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
    props: ["show", "AreaX", "AreaY", "file", "parentfolder", "parentfoldername"],
  components: {
      inboxdetail,
  },
   data: function() {
    return {
      listdowload:[],
      timeout: 60000,
      percentCompleted: 0,
      namedowload: "",
      openinboxdetail:false,
      currentinboxfile:{},
      items: [
        {
          text: "download",
          icon: "mdi-download",
          to: "download",
          event: "fn"
         },
        // {
        //   text: "detail",
        //   icon: "mdi-information",
        //   to: "inboxdetail",
        //   event: "fn",
        // },
      ],
      // showMenu: false,
      x: 0,
      y: 0
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.showmenus();
          this.$emit('closeDrag');
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      }
    },
  },
   methods: {
    goto(parameter) {
      if (parameter === "download") {
        this.$emit('downloadfile');
        console.log("download");       
        this.showMenu = false;
      } else if (parameter === "inboxdetail") {         
        this.openinboxdetail = true;
        this.currentinboxfile = this.file;
      }
    },
    showmenus() {
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    closeDialog() {
      this.openinboxdetail = false;
      this.$emit("loaddatafrommainright");
      this.$emit('openDrag')
    }
  } 
}
</script>
<style>

</style>